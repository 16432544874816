<template>
  <div class="duPinCodeContainer">
    <div class="inputsOfPinCodes">
      <div class="inputHolder" v-for="index in length" :key="'inputHolder' + index">
        <input v-model="state.values[index-1]" type="number" @keyup="(e) => addValue(e, (index - 1))"  @focus="(e) => onFocus(e, (index - 1))" :ref="el => { refs[index-1] = el }" @paste="(e) => onPaste(e)" />
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, onBeforeUpdate, onMounted, reactive, ref} from "vue";

export default {
  name: "DuPinCodeInput",
  props: {
    length: {
      type: Number,
      default: 5,
    },
    onDone: {
      type: Function,
      default: () => {},
    }
  },
  setup(props) {
    const state = reactive({
      values: []
    });
    const refs = ref([]);
    const initRefs = () => {
      refs.value = [];
    };
    onBeforeMount(() => {
      state.values = [];
      for(let i=0; i < (props.length); i++) {
        state.values.push("");
      }
    });
    onBeforeUpdate(() => {
      initRefs();
    });

    const onFocus = (input, index) => {
      let lastEmptyInput = null;
      if(index > 0) {
        for (let i = (props.length - 1); i > 0; i--) {
          if (state.values[i] === "") {
            lastEmptyInput = i - 1;
          }
        }
      }
      if(lastEmptyInput != null) {
        if(state.values[lastEmptyInput] === "") {
          console.log(state.values[lastEmptyInput]);
            refs.value[lastEmptyInput].focus();
        }
      }

    };
    const addValue = (input, index) => {
      let val = "";
      if(/^-?\d+$/.test(input.target.value)) {
        val = input.target.value.charAt(0);
      }
      state.values[index] = val;

        if(val !== "") {
          if(index < (props.length - 1)) {
            refs.value[index + 1].focus();
          }
        } else {
          if(index !== 0) {
            refs.value[index - 1].focus();
          }
        }

      let hasEmptyOne = false;
      let values = "";
      for (let i = 0; i < (props.length); i++) {
        if (state.values[i] === "") {
          hasEmptyOne = true;
        }
        values = values + state.values[i];
      }
      if(!hasEmptyOne) {
        props.onDone(values);
      }

    };

    const onPaste = (input) => {
      console.log(input.clipboardData);
    }
    return {
      onFocus,
      refs,
      onPaste,
      addValue,
      state,
    };
  }
}
</script>

<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
  .duPinCodeContainer{
    display: flex;
    flex-direction: column;
  }
  .inputsOfPinCodes{
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }
  .inputsOfPinCodes .inputHolder{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #999;
    background: #eee;
    width: 50px;
    height: 50px;
  }
  .inputsOfPinCodes .inputHolder input{
    width: 100%;
    height: 100%;
    text-align: center;
    border: 0;
    background: transparent;
    font-size: 22px;
    font-weight: bold;
    place-self: center;
  }
</style>