<template>
  <div class="page">
    <Header />
    <div class="resetPasswordBody">
      <div class="indecatorBar" v-if="state.step !== 4">
        <slot v-for="(step, index) in getStepsIndicator()">
          <div class="cell" :style="getStepStyle(index)">
            <div class="numberBox">{{ index+1 }}</div>
            <span>{{ step.title }}</span>
          </div>
          <div class="fillLine" v-if="index !== 2" :style="getStepStyle(index + 1)"></div>
        </slot>
      </div>

    <div class="resetPasswordBox" v-if="state.step === 1" :style="getResetBoxStyle()">
      <IconsWorker icon="search" class="icon" width="40" color="#777"/>
      <div class="theTitle">إيجاد الحساب</div>
      <div class="theDesc">دعنا نقم بإيجاد حسابك عن طريق كتابة بريدك الإلكتروني أو اسم المستخدم</div>
      <DuInput
          placeholder="اسم المستخدم أو البريد الإلكتروني"
          type="text"
          :is-required="true"
          name="usernameOrEmail"
          :get-ref="(e) => addFormRef(e)"
      />
      <DuButton text="بحث" class="nextButton" @click="search()" :disabled="state.isMakingAction"/>
    </div>

    <div class="resetPasswordBox" v-if="state.step === 2"  :style="getResetBoxStyle()">

      <div class="userInfo">
        <div class="userThumb" :style="getThumb()"></div>
        <div class="infoSide">
          <div class="name">{{ state.data.name }}</div>
          <div class="username">{{ state.data.username }}</div>
        </div>
      </div>
      <div class="notYou" @click="backOneStep()">
        <span>لست أنت ؟</span>
        <span class="clickHere">انقر هنا للبحث مجددًا</span>
      </div>
      <DuInput
          placeholder="كلمة المرور الجديدة"
          type="text"
          :is-required="true"
          min-length="6"
          min-length-error-message="كلمة المرور قصيرة جدًا"
          max-length="20"
          max-length-error-message="كلمة المرور طويلة جدًا"
          name="password"
          :get-ref="(e) => addFormRef(e)"
      />
      <DuButton text="حفظ" class="nextButton" @click="setPassword()" :disabled="state.isMakingAction"/>
    </div>

    <div class="resetPasswordBox" v-if="state.step === 3"  :style="getResetBoxStyle()">
      <IconsWorker icon="mailBoxF" class="icon" width="40" color="#777"/>
      <div class="theTitle">تبقى خطوة واحدة</div>
      <div class="theDesc">لقد قمنا بإرسال رمز تحقق إلى بريدك , رجاء قم بكتابة الرمز بالأسفل</div>
      <DuPinCodeInput :length="6" :onDone="(code) => verifyCode(code)" class="code"/>
    </div>

    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import IconsWorker from "@/components/IconsWorker";
import DuInput from "@/components/DuInput";
import DuButton from "@/components/DuButton";
import {reactive} from "vue";
import AjaxWorker from "@/helpers/AjaxWorker";
import FixedNotification from "@/components/plugins/fixedNotifications/FixedNotification";
import DuPinCodeInput from "@/components/DuPinCodeInput";
import myAccountAsyncStore from "@/store/myAccountAsyncStore";
import router from "@/router";
import BrowserHelper from "@/helpers/BrowserHelper";
export default {
  name: "ResetPassword",
  components: {DuPinCodeInput, DuButton, DuInput, IconsWorker, Header},
  setup() {
    const state = reactive({
      formRefs: {},
      isMakingAction: false,
      step: 1,
      data: {},
    });
    BrowserHelper.changeTitle('استعادة كلمة المرور');
    const addFormRef = (el) => {
      state.formRefs[el.name] = el;
    }
    const search = () => {
      state.isMakingAction = true;
      state.formRefs["usernameOrEmail"].validateNow();
      if(state.formRefs["usernameOrEmail"].state.isValid) {
        state.isMakingAction = false;
        new AjaxWorker().request('/transactions.php?action=findAccountToReset&usernameOrEmail=' + state.formRefs["usernameOrEmail"].state.innerValue).then((res) => {
          //alert(JSON.stringify(res));
          if(res === "NOINTERNETCONNECTION") {
            FixedNotification.create({
              title: 'حدث خطاء أثناء الإتصال',
              decription: 'تأكد من إتصالك بالإنترنت',
              icon: 'wifiF',
              color: 'red',
              hideAfter: 3000
            });
          } else {
            if(res.state) {
              state.step = 2;
              state.data = res;
            } else {
              FixedNotification.create({
                title: 'لم يتم إيجاد الحساب',
                decription: 'حاول مرة أخرى',
                icon: 'searchF',
                color: 'green',
                hideAfter: 3000
              });
            }
          }
        });
      }
    }
    const getStepsIndicator = () => {
      return [
        { title: 'إيجاد الحساب'  },
        { title: 'تغيير كلمة المرور'  },
        { title: 'تأكيد الحساب'  },
      ];
    }
    const getStepStyle = (index) => {
      index = index + 1;
      if(index <= state.step) {
        return {
          background: '#000',
          color: '#fff'
        }
      }
      return {};
    }
    const backOneStep = () => {
      state.step = state.step - 1;
    }
    const getThumb = () => {
      const _style = {
        backgroundImage: `url('${require('@/assets/images/defaultUserThumb.png')}')`,
        'background-position': 'center',
      };
      if(state.data.thumb !== '') {
        const thumb = new AjaxWorker().url + '/media/' + state.data.thumb + "?w=50";
        _style.backgroundImage = `url('${ thumb }')`;
      }
      return _style;
    }

    const setPassword = () => {

      state.isMakingAction = true;
      state.formRefs["password"].validateNow();
      if(state.formRefs["password"].state.isValid) {
        new AjaxWorker().request('/transactions.php?action=resetPassword&password=' + state.formRefs["password"].state.innerValue + "&id=" + state.data.id).then((res) => {
          state.isMakingAction = false;
          //alert(JSON.stringify(res));
          if(res === "NOINTERNETCONNECTION" || !res.state) {
            FixedNotification.create({
              title: 'حدث خطاء أثناء الإتصال',
              decription: 'تأكد من إتصالك بالإنترنت',
              icon: 'wifiF',
              color: 'red',
              hideAfter: 3000
            });
          } else {
            state.step = 3;
          }
        });
      }
    }

    const verifyCode = (code) => {
      state.isMakingAction = true;
        new AjaxWorker().request('/transactions.php?action=activeResetPassword&code=' + code + "&id=" + state.data.id).then((res) => {
          if(res === "NOINTERNETCONNECTION") {
            FixedNotification.create({
              title: 'حدث خطاء أثناء الإتصال',
              decription: 'تأكد من إتصالك بالإنترنت',
              icon: 'wifiF',
              color: 'red',
              hideAfter: 3000
            });
            state.isMakingAction = false;
          } else {
            if(res.state) {
              state.step = 4;
              FixedNotification.create({
                title: 'تم تغيير كلمة المرور',
                decription: 'سيتم تسجيل دخولك الأن',
                icon: 'doneF',
                color: 'green',
                hideAfter: 3000
              });
              myAccountAsyncStore.dispatch('login', { id: res.id, authKey: res.authKey }).then(() => {
                router.replace('/');
              });
            } else {
              FixedNotification.create({
                title: 'رمز التحقق خاطيء',
                decription: 'اعد المحاولة مجددًا',
                icon: 'reloadF',
                color: 'red',
                hideAfter: 3000
              });
              state.isMakingAction = false;
            }
          }
        });
    }
    const getResetBoxStyle = () => {
      if(state.isMakingAction) return {'pointer-events' : 'none', 'opacity': '0.7'};
      return {'pointer-events' : 'auto'};
    }
    return {
      verifyCode,
      getResetBoxStyle,
      setPassword,
      getThumb,
      backOneStep,
      addFormRef,
      getStepStyle,
      getStepsIndicator,
      search,
      state,
    }
  }
}
</script>

<style scoped>
.page{
  background-color: #fff;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.resetPasswordBody{
  margin: 100px 30%;
  width: 40%;
  display: flex;
  flex-direction: column;
}
.resetPasswordBody .indecatorBar{
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.fillLine{
  flex: 1;
  height: 5px;
  background: #eee;
  border-radius: 100px;
}
.resetPasswordBody .indecatorBar .cell{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 100px;
  padding: 5px;
  background: #eee;
  color: #000;
}
.resetPasswordBody .indecatorBar .cell .numberBox{
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  font-size: 15px;
  font-weight: bold;

}
.resetPasswordBody .indecatorBar .cell span{
  padding: 0 10px;
  font-size: 15px;
  font-weight: bold;
}
.resetPasswordBox{
  padding: 20px;
  background: #fff;
  box-shadow:  0 0 10px #ccc;
  border-radius: 10px;
  width: available;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.resetPasswordBox .icon{
  place-self: center;
  margin: 20px 0;
}
.resetPasswordBox .theTitle{
  font-size: 17px;
  font-weight: bold;
}
.resetPasswordBox .theDesc{
  font-size: 15px;
  color: #555;
  padding: 10px 0;
}
.nextButton{
  place-self: end;
  margin-top: 20px;
}
.userInfo{
  width: available;
  padding: 20px;
  background: #eee;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: start;
}
.userThumb{
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
}
.infoSide{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: available;
}
.infoSide .name{
  font-size: 16px;
  color: #111;
}
.infoSide .username{
  font-size: 14px;
  color: #555;
}
.notYou{
  width: available;
  text-align: end;
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 5px;
  cursor: pointer;
}
.notYou:hover{opacity: 0.7}
.notYou:active{opacity: 0.5}
.notYou .clickHere{font-weight: bold; color: #FF0029;}

.code{
  margin-top: 50px;
}

@media only screen and (max-width: 600px) {
  .resetPasswordBody {
    margin: 100px 5%;
    width: 90%;
  }
  .resetPasswordBody .indecatorBar .cell{
    display: flex;
    flex-direction: column;
  }
  .resetPasswordBody .indecatorBar .cell span {
    font-size: 14px;
    font-weight: normal;
  }
}

</style>